<!--
 * @Author: your name
 * @Date: 2021-08-06 16:31:46
 * @LastEditTime: 2021-08-06 16:51:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\focus\components\fwd.vue
-->
<template>
  <div>
    <div class="fwdList" v-if="list.length">
      <el-scrollbar style="height: 100%">
        <smallFWD
         :objData="item"
          style="margin-bottom: 15px"
          v-for="(item, index) in list"
          :key="index"
        />
      </el-scrollbar>
    </div>

    <div class="null flex-v flex-hc flex-vc" v-else>
      <img src="@/assets/img/null.png" />
      <span>暂无数据</span>
    </div>
  </div>
</template>
<script>
import smallFWD from "@/components/small_fwd.vue";
import { attentionList } from "@/api";
export default {
  components: {
    smallFWD,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      attentionList({
        status: 1,
      }).then((res) => {
        this.list = res.data.queueListVoParam;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.null {
  padding-top: 150px;
  img {
    width: 70px;
  }
  span {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ff6e6e;
    margin: 15px 0;
  }
  p {
    width: 169px;
    height: 42px;
    background: #ff6e6e;
    border-radius: 26px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 42px;
  }
}
.fwdList {
  height: 550px;
}
</style>