<!--
 * @Author: your name
 * @Date: 2021-08-06 14:38:31
 * @LastEditTime: 2021-08-24 15:34:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\small_fwd.vue
-->
<template>
  <div class="card flex-h flex-vc" @click="$router.push({name: 'serveDetail',query: {id:objData.queueId}})">
    <img :src="objData.activityLogo" />
    <div class="content">
      <h1>{{ objData.queueName }}</h1>
      <p>发起单位：{{ objData.companyName }}</p>
      <p>
        主要服务方向：<span v-if="objData.serviceOrientation && objData.serviceOrientation[0]">{{ objData.serviceOrientation[0].length>6?objData.serviceOrientation[0].substr(0,5)+"...":objData.serviceOrientation[0] }}</span>
               <span v-if="objData.serviceOrientation && objData.serviceOrientation[1]">{{ objData.serviceOrientation[1].length>6?objData.serviceOrientation[1].substr(0,5)+"...":objData.serviceOrientation[1] }}</span>
               <span v-if="objData.serviceOrientation && objData.serviceOrientation[2]">{{ objData.serviceOrientation[2].length>6?objData.serviceOrientation[2].substr(0,5)+"...":objData.serviceOrientation[2] }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objData: {
      type: Object,
      default: {
        serviceOrientation: [],
      },
    },
  },

  methods: {

  },
  data() {
    return {};
  },
  computed: {
    affirm() {
      return this.$store.state.affirm;
    },
    privilege() {
      return this.$store.state.privilege;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  width: 650px;
  padding: 10px 0;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #e9e5e5;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;
  img {
    width: 92px;
    height: 92px;
    border-radius: 50%;
    margin-left: 20px;
  }
  .content {
    margin-left: 19px;
    h1 {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 6px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #737373;
      span {
        display: inline-block;

        height: 28px;
        background: #dbd7d7;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #797979;
        line-height: 28px;
        margin-right: 7px;
        padding: 0 16px;
        margin-bottom: 5px;
      }
    }
  }
  .type {
    width: 92px;
    height: 33px;

    border-radius: 0px 10px 0px 10px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 33px;
    &.success {
      background: #2ea25b;
    }
    &.loading {
      background: #a8a6a6;
    }
    &.error {
      background: #fb3947;
    }
  }
}
</style>