<!--
 * @Author: your name
 * @Date: 2021-08-06 16:19:28
 * @LastEditTime: 2021-08-06 16:34:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\focus\components\hd.vue
-->
<template>
  <div class="hdList">
    <el-scrollbar style="height: 100%" v-if="list.length">
      <div class="activityList flex-h" >
        <smallHd v-for="(item, index) in list" :objData="item" :key="index" />
      </div>
    </el-scrollbar>
    <div class="null flex-v flex-hc flex-vc" v-else>
      <img src="@/assets/img/null.png" />
      <span>暂无数据</span>
    </div>
  </div>
</template>
<script>
import { attentionList } from "@/api";
import smallHd from "@/components/small_hd.vue";
export default {
  components: {
    smallHd,
  },
  data() {
    return {
     
      list: [],
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      attentionList({
        status: 0,
      }).then((res) => {
        console.log(res);
        this.list = res.data.activityListVoParam;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.hdList {
   height: 550px;
  .activityList {
    flex-wrap: wrap;
    dl {
      width: 245px;
      height: 252px;
      background: #ffffff;
      box-shadow: 0px 6px 4px 0px #d8d8d8;
      border-radius: 10px;
      margin-bottom: 14px;
      cursor: pointer;
      dt {
        position: relative;
        height: 137px;
        img {
          width: 100%;
          height: 100%;
        }
        .tag {
          width: 60px;
          height: 18px;
          background: #fb3947;
          border-radius: 14px;
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 18px;
          position: absolute;
          top: 7px;
          left: 7px;
        }
        .type {
          width: 79px;
          height: 28px;
          border-radius: 0px 0px 0px 10px;
          position: absolute;
          right: 0;
          top: 0;
          text-align: center;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 28px;
          &.success {
            background: #2ea25b;
          }
          &.loading {
            background: #a8a6a6;
          }
          &.error {
            background: #fb3947;
          }
        }
      }
      dd {
        h1 {
          width: 200px;
          height: 28px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1a1a1a;
          line-height: 28px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-left: 10px;
          line-height: 28px;
        }
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
          margin-left: 10px;
          margin-bottom: 2px;
          //   margin-left: 15px;
        }
      }
    }
  }
  .Pagination {
    margin-left: 100px;
  }
  .null {
    padding-top: 150px;
    img {
      width: 70px;
    }
    span {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ff6e6e;
      margin: 15px 0;
    }
    p {
      width: 169px;
      height: 42px;
      background: #ff6e6e;
      border-radius: 26px;
      cursor: pointer;
      text-align: center;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 42px;
    }
  }
}
</style>