<!--
 * @Author: your name
 * @Date: 2021-08-01 13:21:04
 * @LastEditTime: 2021-08-06 16:38:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\userCenter\index.vue
-->
<template>
  <div class="sign">
    <div class="tabs flex-h flex-hc">
      <span :class="{ active: component == 'hd' }" @click="component = 'hd'">活动</span>
      <span :class="{ active: component == 'fwd' }" @click="component = 'fwd'">服务队</span>
    </div>
    <div class="main">
      <div :is='component'></div>
    </div>
  </div>
</template>
<script>
import hd from "./components/hd.vue";
import fwd from "./components/fwd.vue";
export default {
  components: {
    hd,
    fwd
  },
  data() {
    return {
      component:'hd'
    };
  },

  mounted() {
    // this.$bus.$on("cutadmin", (data) => {
    //   this.component = data;
    // });
  },

  
};
</script>
<style lang="scss" scoped>
    .sign {
        width: 822px;
        height: 642px;
        background: #fff6f6;
        border-radius: 23px;
        margin: 0 auto;
        box-sizing: border-box;
        margin-top: 15px;
        padding-top: 1px;

        .tabs {
            margin-left: 22px;
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            // color: #FF6E6E;
            color: #545353;
            margin-top: 17px;

            span {
                margin-right: 55px;
                cursor: pointer;

                &.active {
                    color: #ff6e6e;
                }
            }
        }

        .main {
            margin: 0 auto;
            box-sizing: border-box;
            padding: 26px 26px 0 26px;

            .activityList {
                justify-content: space-between;
                flex-wrap: wrap;

                dl {
                    width: 245px;
                    height: 252px;
                    background: #ffffff;
                    box-shadow: 0px 6px 4px 0px #d8d8d8;
                    border-radius: 10px;
                    margin-bottom: 14px;
                    cursor: pointer;

                    dt {
                        position: relative;
                        height: 137px;

                        img {
                            width: 100%;
                            height: 100%;
                        }

                        .tag {
                            width: 60px;
                            height: 18px;
                            background: #fb3947;
                            border-radius: 14px;
                            text-align: center;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 18px;
                            position: absolute;
                            top: 7px;
                            left: 7px;
                        }

                        .type {
                            width: 79px;
                            height: 28px;
                            border-radius: 0px 0px 0px 10px;
                            position: absolute;
                            right: 0;
                            top: 0;
                            text-align: center;
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #ffffff;
                            line-height: 28px;

                            &.success {
                                background: #2ea25b;
                            }

                            &.loading {
                                background: #a8a6a6;
                            }

                            &.error {
                                background: #fb3947;
                            }
                        }
                    }

                    dd {
                        h1 {
                            width: 200px;
                            height: 28px;
                            font-size: 14px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #1a1a1a;
                            line-height: 28px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            margin-left: 10px;
                            line-height: 28px;
                        }

                        p {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #1a1a1a;
                            margin-left: 10px;
                            margin-bottom: 2px;
                            //   margin-left: 15px;
                        }
                    }
                }
            }

            .Pagination {
                text-align: center;
            }

            .null {
                padding-top: 150px;

                img {
                    width: 70px;
                }

                span {
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #ff6e6e;
                    margin: 15px 0;
                }

                p {
                    width: 169px;
                    height: 42px;
                    background: #ff6e6e;
                    border-radius: 26px;
                    cursor: pointer;
                    text-align: center;
                    font-size: 18px;
                    font-family: MicrosoftYaHei;
                    color: #ffffff;
                    line-height: 42px;
                }
            }
        }
    }
</style>